<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div>
          <div class="page-title">
            <h1>Upgrade Membership</h1>
          </div>

          <div class="page-content" v-if="!isPageLoading">

            <div class="alert alert-success" role="alert" v-if="isPaymentSuccessful">
              Thank you for your purchase.
            </div>

            <div class="alert alert-danger" role="alert" v-if="isPaymentFailure">
              <p> An error has occurred and your transaction has been cancelled - please contact an administrator. </p>
              <p> <strong> Transaction Id: </strong> {{ transactionId }} </p>
            </div>

            <div class="mt-2 mb-5" v-if="companies.length > 1">
              <div class="form-group">
              <label for="purchaserCompany">Company</label>
              <select id="purchaserCompany" name="purchaserCompany" class="custom-select custom-select-sm" :class="{ 'is-invalid': errors.has('purchaserCompany') }"
                  v-model="selectedCompany" v-validate="'required'" @change="onCompanySelection($event.target.value)">
                <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
              </select>
              <div class="invalid-feedback">Please select a company</div>
              <small id="emailHelp" class="form-text text-muted">
                You are associated to multiple companies. Please select which company you are making this purchase for.
              </small>
              </div>
            </div>

            <div class="">
              <div class=" order-md-3 mb-4">
                <h4 class="d-flex justify-content-between align-items-center mb-3">
                  <span class="">Current Memberships</span>
                </h4>
                <ul class="list-group mb-3" v-if="currentMemberships.length">
                  <li class="list-group-item d-flex justify-content-between"
                      v-for="membership in currentMemberships" :key="'current-membership-' + membership.id">
                    <div>
                      <h6 class="my-0">{{ membership.role }} - {{ membership.zone }}</h6>
                      <small class="text-muted">Expires: {{ membership.expiryDate | datetime }}</small>
                    </div>
                  </li>
                </ul>
                <ul class="list-group mb-3" v-else>
                  <li class="list-group-item d-flex justify-content-between">
                    <span class="text-muted">No memberships</span>
                  </li>
                </ul>
              </div>

              <hr class="my-5" />

              <div class=" order-md-1 mb-4">
                <h4 class="mb-3">Upgrade Options</h4>
                <form v-on:submit.prevent="addToCart">
                  <div class="form-group">
                    <label for="roles">Membership Plans</label>
                    <select class="form-control" id="roles" name="roles" v-model="selectedRole">
                      <option v-for="role in roles" :key="role.id" :value="role.id">
                        {{ role.name }} - {{ role.subscriptionPrice | currency(2) }} for {{ role.subscriptionDuration }} month(s)
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="zones">Zone</label>
                    <select class="form-control" id="zones" name="zones" v-model="selectedZone">
                      <option v-for="zone in zones" :key="zone.id" :value="zone.id">{{ zone.name }}</option>
                    </select>
                  </div>
                  <hr class="mb-4">
                  <button class="btn btn-primary btn-lg btn-block" :class="{ 'disabled': !isAllowedCart }" type="submit" :disabled="!isAllowedCart">Add to cart</button>
                </form>
              </div>

              <hr class="my-5" />

              <div class=" order-md-2 mb-4">
                <h4 class="d-flex justify-content-between align-items-center mb-3">
                  <span class="">Cart</span>
                  <span class="badge badge-secondary badge-pill">{{ totalItems }}</span>
                </h4>
                <ul class="list-group mb-3" v-if="cart.length">
                  <li class="list-group-item d-flex justify-content-start"
                      v-for="(item, index) in cart" :key="'cart-item-' + index">
                    <button type="button" class="close mr-3" aria-label="Close" @click="removeFromCart(item)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="flex-fill">
                      <h6 class="my-0">{{ item.role }} - {{ item.zone }}</h6>
                      <small class="text-muted">Duration: {{ item.duration }} month(s)</small>
                    </div>
                    <span class="text-muted">{{ item.price | currency(2) }}</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span>Total (CAD)</span>
                    <strong>{{ totalPrice | currency(2) }}</strong>
                  </li>
                </ul>
                <ul class="list-group mb-3" v-else>
                  <li class="list-group-item d-flex justify-content-between">
                    <span class="text-muted">Empty Cart</span>
                  </li>
                </ul>
                <!-- <hr class="mb-4"> -->

              </div>
            </div>

            <div class="d-flex flex-row-reverse">
              <button class="btn btn-success ml-2" :class="{ 'disabled': !isAllowedCheckout }" type="button"
                  :disabled="!isAllowedCheckout" @click="checkout">
                Checkout
              </button>
              <router-link to="/dashboard" class="btn btn-primary" title="Link to Dashboard">Return to Dashboard</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/toast";
import AppNavBar from "@/components/app-navbar.vue";
import { CompanyService, PaymentService, RoleService, ZoneService } from "@/services/";

export default {
  components: {
    "app-navbar": AppNavBar
  },
  data: function() {
    return {
      isPageLoading: true,
      isPaymentSuccessful: false,
      isPaymentFailure: false,
      isProcessing: false,
      roles: [],
      zones: [],
      companies: [],
      selectedCompany: null,
      currentMemberships: [],
      cart: [],
      selectedRole: null,
      selectedZone: null,
      transactionId: null
    };
  },
  computed: {
    isAllowedCart () {
      return !!this.selectedCompany && !!this.selectedRole && !!this.selectedZone && !this.isCartDuplicate && 
        !this.isMembershipExpired && !this.isMembershipDuplicate
    },
    isAllowedCheckout () {
      return !!this.selectedCompany && !!this.cart.length;
    },
    totalItems () {
      return this.cart.length;
    },
    totalPrice () {
      return this.cart.length > 0 ? this.cart.map(item => item.price).reduce((acc, curr) => acc + curr) : 0;
    },
    isCartDuplicate (){ 
      return this.cart.some(item => item.zoneId === this.selectedZone && item.roleId === this.selectedRole)
    },
    isMembershipDuplicate(){
      return this.currentMemberships.some(item => item.zoneId === this.selectedZone && item.roleId === this.selectedRole)
    },
    isMembershipExpired () {
      return !this.currentMemberships.length > 0
    },
    ...mapGetters(["getCurrentUser"])
  },
  methods: {
    addToCart () {
      let _selectedRole = this.roles.find(role => role.id === this.selectedRole);
      let _selectedZone = this.zones.find(zone => zone.id === this.selectedZone);

      this.cart.push({
        id: '_' + Math.random().toString(36).substr(2, 9),
        duration: _selectedRole.subscriptionDuration,
        price: _selectedRole.subscriptionPrice,
        roleId: _selectedRole.id,
        role: _selectedRole.name,
        zoneId: _selectedZone.id,
        zone: _selectedZone.name
      });

      this.selectedRole = null;
      this.selectedZone = null;

    },
    checkout() {
      this.isProcessing = true;

      let purchasedSubscriptions = {
        companyid: this.selectedCompany,
        purchasedSubscriptions: this.cart.map(item => ({ zoneid: item.zoneId, roleid: item.roleId }))
      };

      PaymentService.membershipUpgrade(purchasedSubscriptions)
        .then(({ headers }) => {
          this.isProcessing = false;
          let location = headers.location;

          if (location) window.location.href = location;
        })
        .catch(err => {
          this.isProcessing = false;
          var message = this.$createElement("span", {},
            ["Oops! An error has occurred and some data could not be loaded", this.$createElement("br"), "Please contact an administrator"]
          );

          if (err && err.response && err.response.data && err.response.data.exceptionMessage) {
            message = this.$createElement("span", {}, [err.response.data.exceptionMessage, this.$createElement("br"), "Please contact an administrator"]);
          }

          Toast.danger(this, [message], { autoHideDelay: 7500 });
        });
    },
    async onCompanySelection(id) {
      try {
        let { data } = await CompanyService.getMemberships(id);
        this.currentMemberships = data;
      }
      catch(err) {
        let message = this.$createElement("span", {}, [
          "Oops! An error has occurred and some data could not be loaded",
          this.$createElement("br"),
          "Please contact an administrator"
        ]);
        Toast.danger(this, [message], { autoHideDelay: 7500 });
      }
    },
    removeFromCart (item) {
      this.cart = this.cart.filter(i => i.id !== item.id);
    },
    ...mapActions([ "fetchUser" ])
  },
  mounted() {
    this.isPaymentSuccessful = this.$route.query.success === 'true';
    this.isPaymentFailure = this.$route.query.success === 'false';
    this.transactionId = this.$route.query.transId;

    Promise.all([
      this.fetchUser(this.getCurrentUser.id),
      RoleService.purchasable(),
      ZoneService.getAll(),
    ])
      .then(result => {
        this.companies = result[0].companies;
        this.companies.length === 1 && (this.selectedCompany = this.companies[0].id) && (this.onCompanySelection(this.selectedCompany));

        this.roles = result[1].data;
        this.zones = result[2].data;

        this.isPageLoading = false;
      })
      .catch(() => {
        this.isPageLoading = false;
        let message = this.$createElement("span", {}, [
          "Oops! An error has occurred and some data could not be loaded",
          this.$createElement("br"),
          "Please contact an administrator"
        ]);
        Toast.danger(this, [message], { autoHideDelay: 7500 });
      });
  }
};
</script>
